// AddInvoice.js
import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Button, Row, Col, message,Select as AntSelect, } from 'antd';
import Select from 'react-select';
import './manageInvoice.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';
const { Option } = AntSelect;
const { TextArea } = Input;
const AddInvoice = ({ }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [organizationId, setOrganizationId] = useState('0');
    const [userId, setUserId] = useState('0');
    const [clientEmail, setClientEmail] = useState('');
    const [clientData, setClientData] = useState([]);
    const [clientId, setClientId] = useState([]);
    const [selectedClientdata, setSelectedClient] = useState('');
    
    const [submitted, setSubmitted] = useState(false);

    const [selectTaxData, setSelectTaxData] = useState([]);
    const [selectConvenienceData, setSelectConvenienceData] = useState([]);
    const [itemId, setItemId] = useState(0);
    const [items, setItems] = useState([]);
    const [deletedItemIndexes, setDeletedItemIndexes] = useState([]);
    const [taxData, setTaxData] = useState('');
    const [totalTax, setTotalTax] = useState(0);
    const [convenienceFee, setConvenienceFee] = useState('');
    const [totalconvenienceFee, setTotalConvenienceFee] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [itmeCount, setItemCount] = useState(1);
    const [invoiceDate, setInvoiceDate] = useState(new Date());
    const [terms, setTerms] = useState(0);
    const [defaultTerms, setDefaultTerms] = useState(0);
    const [dueDate, setDueDate] = useState(null);
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [selectedConvenience, setSelectedConvenience] = useState('');
    const [selectedTaxData, setSelectedTaxData] = useState('');
    const [taxTitle, setTaxTitle] = useState([]);
    const [convenienceFeeTitle, setConvenienceFeeTitle] = useState([]);
    const [taxId, setTaxId] = useState('');
    const [convenienceFeeId, setConvenienceFeeId] = useState('');
    const [isTaxIdExist, setIsTaxExist] = useState(true);
    const [isConvenienceFeeIdExist, setIsonvenienceFeeIdExist] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();
    var invoiceDetails = location.state ? location.state.invoiceDetails : ''
    useEffect(() => {
        if (!invoiceDetails) {
            setItems([{id: itemId, itemName: '', itemDescription: '', itemQty: 1, itemPrice: '', itemAmount: '', invoice_details_id: null }]);
            form.setFieldValue("invoice_number", invoiceNumber);
            setTerms(defaultTerms);
            form.setFieldValue("terms", defaultTerms);
            setIsonvenienceFeeIdExist(true)
            setIsTaxExist(true)

        } else {
            // form.setFieldValue("invoice_number", invoiceDate);
            // console.log(invoiceDetails.invoice_date);
            setInvoiceDate(new Date(invoiceDetails.invoice_date));
            setTerms(invoiceDetails.terms);
            form.setFieldValue("terms", terms);
            setClientId(invoiceDetails.client_id);
            ClientByIdData(invoiceDetails.client_id)
            setIsonvenienceFeeIdExist(false)
            setIsTaxExist(false)
        }
        form.validateFields(['dueDate']);
    }, [invoiceDetails, invoiceNumber]);

    useEffect(() => {
        if (invoiceDetails && invoiceDetails.tbl_invoice_details) {
            let newItemId = itemId;
            setItems(invoiceDetails.tbl_invoice_details.map((item) => {
                newItemId = newItemId + 1;
                setItemId(newItemId);
                return {
                    id: newItemId,
                    itemName: item.item_name || '',
                    itemDescription: item.item_description || '',
                    itemQty: item.item_qty || 1,
                    itemPrice: item.item_amount || '',
                    itemAmount: item.item_total_amount || '',
                    invoice_details_id: item.invoice_details_id || null,
                };
            }));
            setItemCount(invoiceDetails.tbl_invoice_details.length);
            setConvenienceFee(invoiceDetails.tbl_invoice_details[0] ? invoiceDetails.tbl_invoice_details[0].item_convenience_fee : 0);
            setTaxData(invoiceDetails.tbl_invoice_details[0] ? invoiceDetails.tbl_invoice_details[0].item_tax : '');
            setInvoiceNumber(invoiceDetails.invoice_number);
            handleTermsChange({ target: { value: invoiceDetails.terms } });
            setConvenienceFeeTitle(invoiceDetails.tbl_invoice_details[0] && parsable(invoiceDetails.tbl_invoice_details[0].convenience_fee_title) ? JSON.parse(invoiceDetails.tbl_invoice_details[0].convenience_fee_title) : [])
            setTaxTitle(invoiceDetails.tbl_invoice_details[0] && parsable(invoiceDetails.tbl_invoice_details[0].tax_title) ? JSON.parse(invoiceDetails.tbl_invoice_details[0].tax_title) : [])
            setConvenienceFeeId(invoiceDetails.tbl_invoice_details[0] ? invoiceDetails.tbl_invoice_details[0].convenience_fee_id : '')
            setTaxId(invoiceDetails.tbl_invoice_details[0] ? invoiceDetails.tbl_invoice_details[0].tax_id : '')
            setIsonvenienceFeeIdExist(false)
            setIsTaxExist(false)
            setTerms(invoiceDetails.terms);
            form.setFieldValue("terms", terms);
            // if (taxId > 0) {
            //     handleTaxChange(taxId)
            // }
            // if (convenienceFeeId > 0) {
            //     handleConvenienceChange(convenienceFeeId)
            // }

     
        }
    }, [invoiceDetails]);
    function parsable(str) {
        try {
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }
      }
    const initialValues = invoiceDetails
    const checkSessionData = async () => {
        const sessionDataResponse = await fetch(process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/checkSession`, {
            method: 'GET',
            credentials: 'include',
        });

        const sessionData = await sessionDataResponse.json();
        if (sessionData) {
            const user_id = sessionData.data.userId;
            const organization_id = sessionData.data.organizationId;
            setOrganizationId(organization_id);
            setUserId(user_id);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            await allClientData();
            form.validateFields(['dueDate']);
        };
        fetchData();
    }, [organizationId]);
    useEffect(() => {
        const taxAndFeesChange = async () => {
            // console.log(taxId);

            const taxIdArray = Array.isArray(taxId) ? taxId : taxId.split(',').map(Number);
            const convenienceFeeIdArray = Array.isArray(convenienceFeeId) ? convenienceFeeId : convenienceFeeId.split(',').map(Number);
            if (taxIdArray && taxIdArray.length > 0 && selectTaxData && selectTaxData.length > 0) {
                handleTaxChange(taxIdArray);
            }
            
            if (convenienceFeeIdArray && convenienceFeeIdArray.length > 0 && selectConvenienceData && selectConvenienceData.length > 0) {
                handleConvenienceChange(convenienceFeeIdArray);
            }
        };
        taxAndFeesChange();
    }, [taxId,convenienceFeeId,selectConvenienceData,selectTaxData]);
    const handleAddUpdateInvoice = async () => {
        try {
            setLoading(true);
            setSubmitted(true);
            const values = await form.validateFields();
            await checkSessionData();
            values.user_id = userId;
            values.created_by = userId;
            values.email = clientEmail;
            values.tax_percentage = totalTax;
            values.convenience_fee = totalconvenienceFee;
            values.tax_amount = subtotal * parseFloat(totalTax) / 100;
            values.convenience_amount = subtotal * parseFloat(totalconvenienceFee) / 100;
            values.subtotal = subtotal;
            values.total = total;
            values.itmeCount = itmeCount;
            values.invoiceDate = invoiceDate;
            values.due_date = dueDate;
            values.client_id = clientId;
            values.tax_id = taxId;
            values.convenience_fee_id = convenienceFeeId;
            values.tax_title = JSON.stringify(taxTitle);
            values.convenience_fee_title = JSON.stringify(convenienceFeeTitle);
            if (clientId < 1) {
                message.error('Please Select Client');
                return
            }
            values.organization_id = organizationId;
            const apiUrl = invoiceDetails ? process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/invoice/invoice/${invoiceDetails.invoice_id}` : process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/invoice/invoice`;
            const method = invoiceDetails ? 'PATCH' : 'POST';
            const response = await fetch(apiUrl, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ values }),
            });
            if (response.ok) {
                const responseOk = await response.json();
                message.success(responseOk.message)
                navigate("/manage-invoice")
            } else {
                const responseFailed = await response.json();
                message.error(responseFailed.message)
                // console.error('Failed to add/update invoice. Server returned:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error adding/updating invoice:', error);
        } finally {
            setLoading(false);
        }
    };

    const allClientData = async () => {
        await checkSessionData()
        // const clientDataResponse = await fetch(process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/invoice/client?organization_id=${organizationId}`, {
        //     method: 'GET',
        //     credentials: 'include',
        // });
        // const clientDataFinal = await clientDataResponse.json();
        // setClientData(clientDataFinal);
        const taxDataResponse = await fetch(process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/configuration?organization_id=${organizationId}&is_tax=1`, {
            method: 'GET',
        });
        const taxDataFinal = await taxDataResponse.json();
        const applicableTaxes = taxDataFinal.data.filter(tax => tax.is_apply_on === "1");
        const taxIdsdata = applicableTaxes.map(tax => tax.organization_tax_configuration_id).join(',');
        // Set the state with the comma-separated string
        if (isTaxIdExist) {
            setTaxId(taxIdsdata);
        }else{
            setTaxId(taxId);
        }
        setSelectTaxData(taxDataFinal.data);

        const ConvenienceDataResponse = await fetch(process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/configuration?organization_id=${organizationId}&is_tax=0`, {
            method: 'GET',
        });
        const ConvenienceDataFinal = await ConvenienceDataResponse.json();
        const applicableConvenienceFees = ConvenienceDataFinal.data.filter(tax => tax.is_apply_on === "1");
        const ConvenienceIdsdata = applicableConvenienceFees.map(tax => tax.organization_tax_configuration_id).join(',');
        if (isConvenienceFeeIdExist) {
            setConvenienceFeeId(ConvenienceIdsdata);
        }else{
            setConvenienceFeeId(convenienceFeeId);
        }
        setSelectConvenienceData(ConvenienceDataFinal.data);

        const invoiceIdDataResponse = await fetch(process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/invoice/invoice-number?organization_id=${organizationId}`, {
            method: 'GET',
        });
        const invoiceIdDataDataFinal = await invoiceIdDataResponse.json();
        setInvoiceNumber(invoiceIdDataDataFinal.nextInvoiceNumber);
        // setTerms(terms);
        setDefaultTerms(invoiceIdDataDataFinal.terms);
        form.validateFields(['dueDate']);
    }
    const handleClientChange = (value) => {
        const selectedClient = clientData.find(client => client.client_id === value);
        if (selectedClient) {
            setClientEmail(selectedClient.email);
            setClientId(selectedClient.client_id);
            setSelectedClient(selectedClient.client_id);
        }
    };
    
    const handleTaxChange_old = (value) => {
        const selectedTaxData = selectTaxData.find(selectTax => selectTax.organization_tax_configuration_id === value);
        if (selectedTaxData) {
            setTaxData(selectedTaxData.percentage);
            setSelectedTaxData(selectedTaxData);
            setTaxTitle(selectedTaxData.name);
            
            setTaxId(selectedTaxData.organization_tax_configuration_id)
            // setConvenienceFee(selectedTaxData.convenience_fee);
        } else {
            setTaxData(0);
            setSelectedTaxData(null);
            setTaxTitle('');
        }
    };
    const handleTaxChange = (values) => {
        const selectedTaxDataArray = values.map(value => selectTaxData.find(selectTax => selectTax.organization_tax_configuration_id === value));
        if (selectedTaxDataArray[0] && selectedTaxDataArray.length > 0) {
            const selectedTaxIds = selectedTaxDataArray.map(selectTax => selectTax.organization_tax_configuration_id).join(',');
            const taxData = selectedTaxDataArray.map(selectTax => ({ id: selectTax.organization_tax_configuration_id, name: selectTax.name, percentage: selectTax.percentage }));
            const taxTitles = selectedTaxDataArray.map(selectTax => ({ id: selectTax.organization_tax_configuration_id, name: selectTax.name, percentage: selectTax.percentage }));
            const selectedTaxIdsArray = selectedTaxDataArray.map(selectTax => selectTax.organization_tax_configuration_id);
            let totalPercentage = 0;
            for (let i = 0; i < taxData.length; i++) {
                totalPercentage += taxData[i].percentage;
            }
            setTotalTax(totalPercentage)
            const taxChangeData = {
                taxData: taxData,
                taxTitles: taxTitles,
                selectedTaxIdsArray: selectedTaxIdsArray,
                selectedTaxIds: selectedTaxIds
            };
    
            setTaxData(JSON.stringify(taxChangeData.taxData));
            setSelectedTaxData(JSON.stringify(taxChangeData.selectedTaxIds));
            setTaxTitle(taxChangeData.taxTitles);
            setTaxId(taxChangeData.selectedTaxIds);
        } else {
            setTaxData('0');
            setSelectedTaxData(null);
            setTaxTitle('');
            setTaxId('');
            setTotalTax(0)
        }
    };
    const handleConvenienceChange_old = (value) => {
        const selectedConvenienceData = selectConvenienceData.find(selectTax => selectTax.organization_tax_configuration_id === value);
        if (selectedConvenienceData) {
            setConvenienceFee(selectedConvenienceData.percentage);
            setSelectedConvenience(selectedConvenienceData);
            setConvenienceFeeTitle(selectedConvenienceData.name);
            setConvenienceFeeId(selectedConvenienceData.organization_tax_configuration_id);
            // setConvenienceFee(selectedTaxData.convenience_fee);
        } else {
            setConvenienceFee(0);
            setSelectedConvenience(null);
            setConvenienceFeeTitle('');

        }
    };
    const handleConvenienceChange = (values) => {
        const selectedConvenienceDataArray = values.map(value => selectConvenienceData.find(selectTax => selectTax.organization_tax_configuration_id === value));
        if (selectedConvenienceDataArray[0] && selectedConvenienceDataArray.length > 0) {
            const selectedConvenienceIds = selectedConvenienceDataArray.map(selectTax => selectTax.organization_tax_configuration_id).join(',');
            const convenienceFees = selectedConvenienceDataArray.map(selectTax => ({ id: selectTax.organization_tax_configuration_id, name: selectTax.name, percentage: selectTax.percentage }));
            const convenienceTitles = selectedConvenienceDataArray.map(selectTax => ({ id: selectTax.organization_tax_configuration_id, name: selectTax.name, percentage: selectTax.percentage }));
            const selectedConveniences = selectedConvenienceDataArray.map(selectTax => selectTax.organization_tax_configuration_id);
            let percentage = 0;
            for (let i = 0; i < convenienceFees.length; i++) {
                 percentage += convenienceFees[i].percentage;
                
            }
            setTotalConvenienceFee(percentage)
            const convenienceData = {
                convenienceFees: convenienceFees,
                convenienceTitles: convenienceTitles,
                selectedConveniences: selectedConveniences,
                selectedConvenienceIds: selectedConvenienceIds
            };
    
            // Assuming you want to set this JSON object in the state
            setConvenienceFee(JSON.stringify(convenienceData.convenienceFees));
            setSelectedConvenience(JSON.stringify(convenienceData.selectedConveniences));
            setConvenienceFeeTitle(convenienceData.convenienceTitles);
            setConvenienceFeeId(convenienceData.selectedConvenienceIds);
        } else {
            setConvenienceFee('0');
            setSelectedConvenience(null);
            setConvenienceFeeTitle('');
            setConvenienceFeeId('');
            setTotalConvenienceFee(0)
        }
    };
    
    // Modify handleAddItem function to correctly assign unique IDs
    const handleAddItem = () => {
        const newItemId = itemId + 1;
        // console.log('Current itemId:', itemId);
        // console.log('New itemId:', newItemId);
        setItems([...items, { id: newItemId, itemName: '', itemDescription: '', itemQty: 1, itemPrice: '', itemAmount: '' }]);
        setItemId(newItemId); // Update itemId state
        setDeletedItemIndexes([]);
        setItemCount(prevCount => prevCount + 1);
    };
    
    const handleRemoveItem = (idToRemove) => {
        setItems(prevItems => {
            // Filter out the item with the specified ID
            const updatedItems = prevItems.filter(item => item.id !== idToRemove);
            return updatedItems;
        });
        setItemCount(prevCount => prevCount - 1);
    };
    
    
    const calculateTotals = useCallback(() => {
        let updatedSubtotal = 0;

        items.forEach((item) => {
            const qty = parseFloat(item.itemQty) || 1;
            const amount = parseFloat(item.itemPrice) || 0;
            updatedSubtotal += qty * amount;
        });

        const taxAmount = (updatedSubtotal * (parseFloat(totalTax) || 0)) / 100;
        const convenienceFeeAmount = (updatedSubtotal * (parseFloat(totalconvenienceFee) || 0)) / 100;
        var updatedTotal = updatedSubtotal + taxAmount + convenienceFeeAmount;
        setSubtotal(updatedSubtotal);
        setTotal(updatedTotal);
    }, [items, taxData, convenienceFee,taxTitle, convenienceFeeTitle]);

    useEffect(() => {
        calculateTotals();
    }, [items, taxData, convenienceFee, taxTitle, convenienceFeeTitle]);

    const onItemChange = (index, field, value, item_id) => {
        const updatedItems = [...items];
        const itemIndex = updatedItems.findIndex(item => item.id === item_id);
        
        if (itemIndex !== -1) {
            updatedItems[itemIndex][field] = value;
    
                if (field === 'itemQty' || field === 'itemPrice') {
                const isValidNumber = /^[0-9]*$/.test(updatedItems[itemIndex]['itemQty']);

                if (!isValidNumber) {
                    form.setFieldsValue({
                        [`items[${item_id}].itemQty`]: 1
                    });
                }
                // Calculate item amount when itemQty or itemPrice changes
                const qty = parseFloat(updatedItems[itemIndex]['itemQty']) || 0;
                const price = parseFloat(updatedItems[itemIndex]['itemPrice']) || 0;
                updatedItems[itemIndex]['itemAmount'] = (qty * price).toFixed(2);
    
                // Set the calculated itemAmount in the form field
                form.setFieldsValue({
                    [`items[${item_id}].itemAmount`]: updatedItems[itemIndex]['itemAmount']
                });
                
            }
    
            setItems(updatedItems);
            calculateTotals();
        } else {
            console.warn(`Item with ID ${item_id} not found.`);
        }
    };
    

    const handleDateChange = (date) => {
        setInvoiceDate(date);
    };
    const renderLabel = (label, required) => (
        <span style={{ fontWeight: 'bold' }}>
            {label} {required && <span style={{ color: 'red' }}>*</span>}
        </span>
    );

    const handleTermsChange = (e) => {
        const newTerms = e.target.value ? e.target.value : 0;
        if (newTerms) {
            setTerms(newTerms);
        } else {
            setTerms(0);
        }
        const newDueDate = calculateDueDate(invoiceDate, newTerms);
        setDueDate(newDueDate);
        form.validateFields(['dueDate']);
    };
    useEffect(() => {
        handleDateChange(invoiceDate);
        handleTermsChange({ target: { value: terms } });
        form.validateFields(['dueDate']);
        // calculateTotals();
    }, [invoiceDate, terms]);
    const calculateDueDate = (invoiceDate, terms) => {
        const dueDate = addDays(invoiceDate, parseInt(terms, 10));
        return dueDate;
    };
    const onSearchClient = async (value) => {
        try {
            setLoading(true);
            // Fetch client data based on the search value
            const response = await fetch(
                `${process.env.REACT_APP_MASTER_URL}:${process.env.REACT_APP_PORT}/invoice/client/search?organization_id=${organizationId}&search=${value}`,
                {
                    method: 'GET',
                    credentials: 'include',
                }
            );

            if (!response.ok) {
                setClientData([]);
            }

            const clientDataFinal = await response.json();
        // console.log('clientDataFinal',clientDataFinal);

            setClientData(clientDataFinal);
        } catch (error) {
            console.error('Error fetching client data:', error);
        } finally {
            setLoading(false);
        }
    };
    const ClientByIdData = async (clientId) => {
        const clientDataResponse = await fetch(process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/invoice/client/${clientId}`, {
            method: 'GET',
            credentials: 'include',
        });

        const clientDataFinal = await clientDataResponse.json();
        // onSearchClient(clientDataFinal.client_first_name);
        // const finaldataToReturn = [clientDataFinal];
        var selected_data = getOptionContent(clientDataFinal)
        setSelectedClient(selected_data)
        // console.log(finaldataToReturn);
        // setClientData(clientDataFinal);
    };
    const getOptionContent = (client) => {
        if(client.client_last_name == '' && client.client_first_name == ''){
            return `${client.email}`;
        }else{
            return `${client.client_first_name} ${client.client_last_name} - ${client.email}`;
        }
        // return `${client.client_first_name} ${client.client_last_name}`;
    };
    const getDueDateMessage = () => {
        if (terms == 0) {
            return 'On Invoice Date';
        }
        return '';
    };
    return (
        <div className='theme-container bg-transparent border-0'>
            <div className='button-container btn-container-design'>
                <div className='title-div'>
                    <h5 className='mb-0'>{invoiceDetails ? 'Update' : 'Add'} Invoice</h5>
                </div>
                <div className='button-div' style={{ marginLeft: 'auto' }}>
                    <Button className='btn-primary' type='primary' onClick={() => navigate("/manage-invoice")}>Manage Invoice</Button>
                </div>

            </div>
            <div className='button-container'>
                <Form className='pt-3' form={form} layout="vertical" initialValues={initialValues} >
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='white_box'>
                                <div className='form-group'>
                                    <Form.Item
                                        label={renderLabel("Bill To", true)}
                                        rules={[{ required: true, message: 'Select Client' }]}
                                        validateStatus={submitted && clientId < 1 ? 'error' : ''}
                                        help={submitted && clientId < 1 ? 'Please select a client' : ''}
                                    >
                                        <AntSelect
                                            className='text-left'
                                            placeholder="Select Client"
                                            onChange={handleClientChange}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={false}
                                            onSearch={(value) => {
                                                onSearchClient(value);
                                            }}
                                            value={selectedClientdata}
                                        >
                                            {clientData.length > 0 ? (
                                                clientData.map((client) => (
                                                    <Option key={client.client_id} value={client.client_id}>
                                                        {getOptionContent(client)}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value={''} disabled>
                                                    Please search client
                                                </Option>
                                            )}
                                        </AntSelect>
                                    </Form.Item>

                                </div>
                                <label>Items</label>
                                {items.map((item, index) => (
                                    <div className='item_box mb-2' key={item.id}>

                                        <div className='row'>
                                            <div className='col-lg-5 custom-pr-5'>
                                                <div className='form-group mb-2'>
                                                    <Form.Item
                                                        // label={renderLabel("Name", false)}
                                                        name={`items[${item.id}].invoice_details_id`} // Include invoice_details_id in the name
                                                        hidden
                                                        initialValue={item.invoice_details_id || ''} // Use the existing invoice_details_id if available
                                                    >
                                                        <Input type="hidden" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        // label={renderLabel("Name", true)}
                                                        name={`items[${item.id}].itemName`}
                                                        rules={[{ required: true, message: 'Enter item name' }]}
                                                        initialValue={item.itemName || ''}
                                                    >
                                                        <Input placeholder="Name" className='form-control' onChange={(e) => onItemChange(index, 'itemName', e.target.value,item.id)} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-lg-2 custom-pl-5 custom-pr-5'>
                                                <div className='form-group mb-2'>
                                                    <Form.Item
                                                        // label={renderLabel("Quantity", true)}
                                                        name={`items[${item.id}].itemQty`}
                                                        rules={[
                                                            { required: true, message: 'Enter quantity' },
                                                            { pattern: /^[0-9]*$/, message: 'Enter a valid number' },
                                                        ]}
                                                        initialValue={item.itemQty || 1}
                                                    >
                                                        <Input placeholder="Quantity" className='form-control' onChange={(e) => onItemChange(index, 'itemQty', e.target.value,item.id)} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-lg-2 custom-pl-5 custom-pr-5'>
                                                <div className='form-group mb-2'>
                                                    <Form.Item
                                                        // label={renderLabel("Price", true)}
                                                        name={`items[${item.id}].itemPrice`}
                                                        rules={[
                                                            { required: true, message: 'Enter price' },
                                                            { pattern: /^[0-9]+(\.[0-9]+)?$/, message: 'Enter a valid number' },
                                                        ]}
                                                        initialValue={item.itemPrice || ''}
                                                    >
                                                        <Input placeholder="Price" className='form-control' onChange={(e) => onItemChange(index, 'itemPrice', e.target.value,item.id)} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 custom-pl-5'>
                                                <div className='form-group mb-2'>
                                                    <Form.Item
                                                        // label={renderLabel("Amount", false)}
                                                        name={`items[${item.id}].itemAmount`}
                                                        initialValue={item.itemAmount || ''}
                                                    >
                                                        <Input readOnly placeholder="Amount" className='form-control' />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-lg-12'>
                                                <div className='form-group mb-2'>
                                                    <Form.Item
                                                        // label={renderLabel("Item Description", false)}
                                                        name={`items[${item.id}].itemDescription`}
                                                        initialValue={item.itemDescription || ''}
                                                        type='text-area'
                                                    >
                                                        <TextArea rows={3} placeholder="Description (optional)" className='form-control' onChange={(e) => onItemChange(index, 'itemDescription', e.target.value,item.id)} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-lg-12'>
                                                {index !== 0 && (
                                                    <Button type="dashed" icon={<CloseCircleOutlined />} className='remove-item-btn'  onClick={() => {
                                                        handleRemoveItem(item.id);
                                                    }}>
                                                        Delete Item</Button>
                                                )}
                                                {/* <p className='item_box_amount'>{item.id['itemAmount'] ? `Amount: $${item.id['itemAmount']}` : ''}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <Button type="dashed" className='add-item-btn' icon={<PlusOutlined />} onClick={handleAddItem}>
                                    Add Item
                                </Button>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='white_box'>
                                <div className='form-group mb-1'>
                                    <Form.Item style={{ marginBottom: '0px' }}
                                        label={renderLabel("Invoice Number", true)}
                                        name="invoice_number"
                                        rules={[{ required: true, message: 'Please enter the Invoice Number' }]}
                                    >
                                        <Input placeholder="Enter Invoice Number" className='form-control' />
                                    </Form.Item>
                                </div>
                                <div className='form-group mb-1'>
                                    <Form.Item style={{ marginBottom: '0px' }} label={renderLabel("Invoice Date", false)}>
                                        <DatePicker
                                            selected={invoiceDate}
                                            onChange={handleDateChange}
                                            dateFormat="MM/dd/yyyy"
                                            className="form-control"
                                        />
                                    </Form.Item>
                                </div>
                                <div className='form-group mb-1'>
                                    <Form.Item style={{ marginBottom: '0px'}}
                                        label={renderLabel("Terms(In Days)", true)}
                                        name="terms"
                                        onChange={handleTermsChange}
                                        rules={[{ required: true, message: 'Please enter the terms' },
                                        { pattern: /^[0-9]*$/, message: 'Please enter a valid numeric number' },
                                        ]}
                                    >
                                        <Input placeholder="Enter Terms" className='form-control' />
                                    </Form.Item>
                                </div>
                                <div className='form-group mb-1'>
                                    <Form.Item
                                        label={renderLabel("Due Date", false)}
                                        name="dueDate"
                                        help={getDueDateMessage()}
                                        validateStatus={terms == '0' ? 'warning' : ''}
                                    >
                                        <DatePicker
                                            selected={dueDate}
                                            dateFormat="MM/dd/yyyy"
                                            className="form-control"
                                            disabled
                                        />
                                    </Form.Item>
                                </div>
                                {selectTaxData.length > 0 ? (
                                <div className='form-group tax_selection mb-1'>
                                    <div className='select_tax'>
                                        <label htmlFor="tax">Select Tax</label>
                                        <Form.Item name="tax_id">

                                            {selectTaxData.length > 0 ? (
                                                <Select
                                                    className='text-left'
                                                    placeholder="Select Tax"
                                                    isMulti
                                                    onChange={selectedOptions => handleTaxChange(selectedOptions.map(option => option.value))}
                                                    value={taxId 
                                                    ? selectTaxData
                                                        .filter(tax => taxId.split(',').includes(tax.organization_tax_configuration_id))
                                                        .map(tax => ({
                                                            value: tax.organization_tax_configuration_id,
                                                            label: tax.name
                                                        }))
                                                    : []
                                                    }
                                                    options={selectTaxData.map(selectTax => ({
                                                        value: selectTax.organization_tax_configuration_id,
                                                        label: selectTax.name
                                                    }))}
                                                />
                                            ) : (
                                                
                                                <span>Loading tax data...</span>
                                            )}

                                            {/* <label style={{ fontWeight: 'bold' }}>{selectedTaxData ? selectedTaxData.name : ''}</label> */}

                                        </Form.Item>
                                    </div>

                                </div>
                                ): (
                                    ''
                                )}
                                {selectConvenienceData.length > 0 ? (
                                <div className='form-group tax_selection mb-1'>
                                    <div className='select_tax'>
                                        <label htmlFor="tax">Additional Fees</label>
                                        <Form.Item name="convenience_fee_id">
                                            {selectConvenienceData.length > 0 ? (
                                                <Select
                                                className='text-left'
                                                placeholder="Select Additional fees"
                                                isMulti
                                                onChange={selectedOptions => handleConvenienceChange(selectedOptions.map(option => option.value))}
                                                value={convenienceFeeId 
                                                  ? selectConvenienceData
                                                      .filter(fee => convenienceFeeId.split(',').includes(fee.organization_tax_configuration_id))
                                                      .map(fee => ({
                                                        value: fee.organization_tax_configuration_id,
                                                        label: fee.name
                                                      }))
                                                  : []
                                                }
                                                options={selectConvenienceData.map(selectTax => ({
                                                    value: selectTax.organization_tax_configuration_id,
                                                    label: selectTax.name
                                                }))}
                                             />
                                            ) : (
                                                <span>Loading tax data...</span>
                                            )}
                                            {/* <label style={{ fontWeight: 'bold' }}>{selectedConvenience ? selectedConvenience.name : ''}</label> */}
                                        </Form.Item>
                                    </div>
                                </div>
                                 ) : (
                                    ''
                                )}
                               <div className='form-group total_box'>
                                    {totalTax > 0 || totalconvenienceFee > 0 ? (
                                        <h4>Subtotal <span>{subtotal.toFixed(2)}</span></h4>
                                    ) : ('')}
                                    
                                    {totalTax > 0 && taxTitle ? (
                                        taxTitle.map((taxItem, index) => (
                                            <h4 key={index}>
                                                <label>{taxItem.name}</label> {"@ " + taxItem.percentage + "%"} <span>{((subtotal * parseFloat(taxItem.percentage)) / 100).toFixed(2)}</span>
                                            </h4>
                                        ))
                                    ) : ('')}
                                    
                                    {totalconvenienceFee > 0 && convenienceFeeTitle ? (
                                        convenienceFeeTitle.map((convenienceItem, index) => (
                                            <h4 key={index}>
                                                <label>{convenienceItem.name}</label> {"@ " + convenienceItem.percentage + "%"} <span>{((subtotal * parseFloat(convenienceItem.percentage)) / 100).toFixed(2)}</span>
                                            </h4>
                                        ))
                                    ) : ('')}
                                    
                                    <h4>Total <span>{(subtotal + ((subtotal * parseFloat(totalTax)) / 100) + ((subtotal * parseFloat(totalconvenienceFee)) / 100)).toFixed(2)}</span></h4>
                                </div>

                            </div>
                        </div>
                    </div>


                    <Button className='btn-primary col-lg-3 mt-3 d-block m-auto' type="primary" loading={loading} onClick={handleAddUpdateInvoice}>
                        {invoiceDetails ? 'Update' : 'Save'}
                    </Button>
                </Form>
            </div>
        </div>
    );
};


export default AddInvoice;
