import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Table, Input, Button, Pagination, message, Tag, Modal, Spin,DatePicker,Select } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'antd/dist/antd';
import './manageInvoice.css';
const { RangePicker } = DatePicker;
const { Option } = Select;
const ManageInvoices = () => {
  const { id } = useParams();
  const [clientId, setClientId] = useState('');
  const [isClientId, setIsClientId] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState('');
  const [clientData, setClientData] = useState([]);
  const [clientDetails, setClientDetails] = useState([]);

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalLink, setModalLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([
    moment().subtract(1, 'month'),
    moment(),
  ]);
  const [selectedOption, setSelectedOption] = useState("1");
  const [sortColumn, setSortColumn] = useState('invoice_id');
  const [sortOrder, setSortOrder] = useState('desc');

  const [paymentStatus, setPaymentStatus] = useState('all');
  const [totalDueAmount, setTotalDueAmount] = useState(0);
  const [totalRecievableAmount, setTotalRecievableAmount] = useState(0);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
  const [totalPaymentReceived, setTotalPaymentReceived] = useState(0);
  const [organizationId, setOrganizationId] = useState('');
  const navigate = useNavigate();
 
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        setLoading(true);
        const sessionDataResponse = await fetch(process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/checkSession`, {
          method: 'GET',
          credentials: 'include',
        });

        const sessionData = await sessionDataResponse.json();
        const user_id = sessionData.data.userId;
        const organizationId = sessionData.data.organizationId;
        setOrganizationId(organizationId);
        let url = `${process.env.REACT_APP_MASTER_URL}:${process.env.REACT_APP_PORT}/invoice/invoice?organization_id=${organizationId}&page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`;
        
        
        if (id) {
          setClientId(id);
          await handleClientById(id)
        }
        if (clientId && id) {
          url += `&clientId=${clientId}`;
        }else{
          if (dateRange.length === 2) {
            const [startDate, endDate] = dateRange;
            url += `&startDate=${startDate.toISOString().substring(0,10)}&endDate=${endDate.toISOString().substring(0,10)}`;
          }
          if (paymentStatus !== '') {
            url += `&paymentStatus=${paymentStatus}`;
          }
          if (clientId && isClientId) {
            url += `&clientId=${clientId}`;
          }
        }
        const response = await fetch(url,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const responseData = await response.json();
        setData(responseData.data);
        setTotalCount(responseData.total);
        const summaryData = await getSummarydata(organizationId);
        setTotalInvoiceAmount(summaryData.totalInvoiceAmount ? summaryData.totalInvoiceAmount :'0')
        setTotalRecievableAmount(summaryData.totalPaymentReceivable ? summaryData.totalPaymentReceivable : '0');
        setTotalDueAmount(summaryData.totalFuturePaymentReceivable ? summaryData.totalFuturePaymentReceivable : '0');
        setTotalPaymentReceived(summaryData.totalPaymentReceived ? summaryData.totalPaymentReceived : '0');
      } catch (error) {
        console.error('Error fetching table data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTableData();
  }, [page, pageSize, searchTerm,dateRange,paymentStatus,sortColumn,sortOrder,clientId,id]);

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSortColumn(sorter.field);
      setSortOrder(sorter.order === 'ascend' ? 'asc' : 'desc');
    }
  };
  const handleAddNewInvoice = () => {
    navigate('/add-invoice');
  };
  const handleClientById = async (clientId) => {
    try {
      
      const response = await fetch(process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/client/${clientId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const clientDetails = await response.json();
      setClientDetails(clientDetails)
    
    } catch (error) {
      console.error('Error fetching contact details:', error);
    }
  };
  const handleEdit = async (invoiceId) => {
    try {
      const response = await fetch(process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/invoice/invoice/${invoiceId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const invoiceDetails = await response.json();
      navigate('/add-invoice', { state: { invoiceDetails } });
    } catch (error) {
      console.error('Error fetching contact details:', error);
    }
  };
  const  getSummarydata = async (organizationId) => {
    try {
      let url = `${process.env.REACT_APP_MASTER_URL}:${process.env.REACT_APP_PORT}/invoice/summary?organization_id=${organizationId}`;
      if (clientId && id) {
        url += `&clientId=${clientId}`;
      }else{
        if (dateRange.length === 2) {
          const [startDate, endDate] = dateRange;
          url += `&startDate=${startDate.toISOString().substring(0,10)}&endDate=${endDate.toISOString().substring(0,10)}`;
        }
        if (clientId && isClientId) {
          url += `&clientId=${clientId}`;
        }
      }
      // if (dateRange.length === 2) {
      //   const [startDate, endDate] = dateRange;
      //   url += `&startDate=${startDate.toISOString().substring(0,10)}&endDate=${endDate.toISOString().substring(0,10)}`;
      // }
      const response = await fetch(url, 
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

      const summarydata = await response.json();
      return summarydata;
    } catch (error) {
      console.error('Error fetching contact details:', error);
    }
  };
  const handleCopyLink = (link) => {
    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.value = link;
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setModalLink(link);
    if (link.includes('payment-invoice')) {
      setModalVisible(true);
    }
    message.success('Link copied to clipboard');
  };
  const convertToInvoiceLink = (paymentLink) => {
    const invoiceLinkId = paymentLink.replace('shift4payment?id=', 'shift4payment/payment-invoice?id=');
    return invoiceLinkId;
  };
  const handleModalCancel = () => {
    setModalVisible(false);
  };
  const handleDateRange = (value) => {
    if (value == 1) {
      setDateRange([moment().subtract(1, 'month'),moment()]);
    }else if(value == 2){
      setDateRange([moment().subtract(2, 'month'),moment()]);
    }else if(value == 3){
      setDateRange([moment().subtract(3, 'month'),moment()]);
    }
    setSelectedOption(value);
  };
  const disabledFutureDate = current => {
    return current && current > moment().endOf('day');
  };
  const handleClientChange = (value) => {
      const selectedClient = clientData.find(client => client.client_id == value);
      if (selectedClient) {
        setClientId(selectedClient.client_id)
        setSelectedClientId(selectedClient.client_id)
        setIsClientId(true)
      }
  };
  const getOptionContent = (client) => {
      if(client.client_last_name == '' && client.client_first_name == ''){
          return `${client.email}`;
      }else{
          return `${client.client_first_name} ${client.client_last_name} - ${client.email}`;
      }
  };
  const onSearchClient = async (value) => {
      try {
          setLoading(true);
          // Fetch client data based on the search value
          const response = await fetch(
              `${process.env.REACT_APP_MASTER_URL}:${process.env.REACT_APP_PORT}/invoice/client/search?organization_id=${organizationId}&search=${value}`,
              {
                  method: 'GET',
                  credentials: 'include',
              }
          );

          if (!response.ok) {
              setClientData([]);
          }

          const clientDataFinal = await response.json();
      // console.log('clientDataFinal',clientDataFinal);

          setClientData(clientDataFinal);
      } catch (error) {
          console.error('Error fetching client data:', error);
      } finally {
          setLoading(false);
      }
  };
  const columns = [
    { title: 'ID',  dataIndex: 'index', key: 'index', render: (text, record, index) => (page - 1) * pageSize + index + 1 },
    // { title: 'Invoice Number', dataIndex: 'invoice_number', key: 'invoice_number' },
    {
      title: 'Invoice Number',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sorter:true,
      render: (text, record) => {
        const { payment_status } = record;
        if (payment_status == 0) {
          return <Button type="link" onClick={() => handleEdit(record.invoice_id)}>{text}</Button>;
        }
        return <span>&nbsp;&nbsp;&nbsp;&nbsp;{text}&nbsp;&nbsp;</span>;
      }
    },
    {
      title: 'Client',
      dataIndex: ['tbl_client', 'client_company_name'],
      key: 'client_company_name',
      render: (text, record) => (
        <div>
          {record.tbl_client.client_first_name === '' && record.tbl_client.client_last_name === '' ? (
            <div style={{ width: '200px' }}>
              <div><strong>Company:</strong> {record.tbl_client.client_company_name}</div>
            </div>
          ) : (
            <div style={{ width: '200px' }}> {/* Adjust the width based on your preference */}
              <div><strong></strong> {record.tbl_client.client_first_name} {record.tbl_client.client_last_name}</div>
              <hr />
              <div><strong>Company:</strong> {record.tbl_client.client_company_name}</div>
            </div>
          )}
        </div>
      ),
    },
    { title: 'Invoice Date', dataIndex: 'invoice_date', key: 'invoice_date',sorter:true, render: (text, record) => moment(record.invoice_date).format('MM/DD/YYYY'), },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date',
      sorter:true,
      render: (text, record) => moment(record.due_date).format('MM/DD/YYYY'),
    },
    { title: 'Invoice Amount', dataIndex: 'invoice_amount', key: 'invoice_amount',sorter:true, render: (text, record) => { return <span>${text}</span>; } },
    {
      title: 'Payment Status',
      dataIndex: 'payment_status',
      key: 'payment_status',
      sorter:true,
      render: (paymentStatus, record) => {
        const isDue = moment(record.due_date).isBefore(moment(), 'day');
        if (isDue && paymentStatus != 1) {
          return (
            <Tag color="red">Due</Tag>
          );
        } else {
          return (
            <Tag color={paymentStatus == 1 ? 'green' : 'orange'}>
              {paymentStatus == 1 ? 'Success' : 'Pending'}
            </Tag>
          );
        }
      },
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '2px' }}>
          <Button onClick={() => handleCopyLink(record.link)}>
            Payment Link
          </Button>
          <Button onClick={() => handleCopyLink(convertToInvoiceLink(record.link))}>
            Invoice Link
          </Button>
        </div>
      ),
    },
    // {
    //   title: 'Created Date',
    //   dataIndex: 'created_date',
    //   key: 'created_date',
    //   render: (text, record) => moment(record.created_date).format('MM/DD/YYYY hh:mm A'),
    // },
    {
      title: 'Action',
      dataIndex: 'invoice_id',
      key: 'action',
      render: (invoiceId, record) => {
        const { payment_status } = record;
            if (payment_status == 0) {
              return (
                <Button type="link" onClick={() => handleEdit(invoiceId)} icon={<EditFilled />} >Edit </Button>
              );
            }
            return null;
      },
    }
  ];

  return (
    <div className='theme-container'>
      <div className='button-container'>
        <div className='title-div'>
          <h5 className='mb-0'>{ id ? `Transaction : ${clientDetails.client_first_name ? clientDetails.client_first_name : ''} ${clientDetails.client_last_name ? clientDetails.client_last_name + ',' : ''}  ${clientDetails.client_company_name ? clientDetails.client_company_name : ''}` : 'Manage Invoice'} </h5>
        </div>
        <div className='button-div' style={{ marginLeft: 'auto' }}>
          <Button className='btn-primary' type='primary' onClick={handleAddNewInvoice}>Add Invoice</Button>
        </div>
      </div>
        <hr/>
        <div className="invoice-summary" style={{display: id ? 'block' : 'block'}}>
          <ul>
            <li><p>Total Invoice Amount: <span>${totalInvoiceAmount}</span></p></li>
            <li><p>Total Amount Received: <span style={{ color: 'green' }}>${totalPaymentReceived}</span></p></li>
            <li><p>Total Receivable Amount: <span style={{ color: 'orange' }}>${totalRecievableAmount}</span></p></li>
            <li><p>Total Due Amount: <span style={{ color: 'red' }}>${totalDueAmount}</span></p></li>
          </ul>
        </div>
        <div className="invoice-summary" style={{display: id ? 'none' : 'none'}}>
          <ul>
            <li style={{display: clientDetails.client_first_name || clientDetails.client_first_name ? 'block' : 'none'}}><p><span> {clientDetails.client_first_name} {clientDetails.client_last_name} </span></p></li>
            <li style={{display: clientDetails.client_company_name ? 'block' : 'none'}}><p><span >{clientDetails.client_company_name}</span></p></li>
            <li style={{display: clientDetails.email ? 'block' : 'none'}}><p><span>{clientDetails.email}</span></p></li>
            {/* <li><p>Total Due Amount: <span style={{ color: 'red' }}>${totalDueAmount}</span></p></li> */}
          </ul>
        </div>
        <hr style={{display: id ? 'block' : 'block'}} />
      <div className='search-container' style={{display: id ? 'none' : 'block'}}>
        {/* <Select
              className='text-left'
              placeholder="Select Client"
              onChange={handleClientChange}
              showSearch
              optionFilterProp="children"
              filterOption={false}
              onSearch={(value) => {
                  onSearchClient(value);
              }}
              value={selectedClientId}
          >
              {clientData.length > 0 ? (
                  clientData.map((client) => (
                      <Option key={client.client_id} value={client.client_id}>
                          {getOptionContent(client)}
                      </Option>
                  ))
              ) : (
                  <Option value={''} disabled>
                      Select client
                  </Option>
              )}
        </Select> */}
        <Select
          defaultValue="1"
          onChange={(value) => handleDateRange(value)}
        >
          <Option value="1">Last 30 Days</Option>
          <Option value="2">Last 60 Days</Option>
          <Option value="3">Last 90 Days</Option>
          <Option value="4">Custom Range</Option>
        </Select>
        {selectedOption === "4" && (
          <RangePicker
            onChange={(dates) => {
              setDateRange(dates);
            }}
            format="MM/DD/YYYY"
            disabledDate={disabledFutureDate}
          />
        )}
        <Select
          defaultValue="all"
          style={{ width: 120, marginLeft: 10 }}
          onChange={(value) => setPaymentStatus(value)}
        >
          <Option value="all">All</Option>
          <Option value="1">Success</Option>
          <Option value="0">Pending</Option>
          <Option value="2">Due</Option>
        </Select>
        <Input
          placeholder='Search'
          prefix={<SearchOutlined />}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className='table-container'>
        <Pagination
          showSizeChanger
          pageSizeOptions={['10', '20', '30', '40']}
          onShowSizeChange={(current, newSize) => setPageSize(newSize)}
        />
        <Spin spinning={loading}>
          <Table dataSource={data} columns={columns} pagination={false} onChange={handleTableChange} rowKey={(record) => record.invoice_id} responsive scroll={{ x: true }}/>
        </Spin>
      </div>

      <div className='pagination-container'>
        <Pagination
          pageSize={pageSize}
          total={totalCount}
          onChange={(newPage) => setPage(newPage)}
          showQuickJumper
          showTotal={(total) => `Total ${total} items`}
        />
      </div>
      <Modal
        title='Invoice Details'
        open={modalVisible}
        onCancel={handleModalCancel}
        footer={null}
        width={900}
      >
        <iframe title='Link Content' src={modalLink} style={{ width: '100%', height: '500px', border: 'none' }} />
      </Modal>
    </div>
  );
};

export default ManageInvoices;
