// AddContact.js
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Select, Alert,message} from 'antd';
import './manageConfiguration.css';
import { useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
const { Option } = Select;

const AddTerms = ({ onSubmit }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [organizationId, setOrganizationId] = useState('');

  const fetchOrganizationIdFromSession = async () => {
    try {
      const sessionDataResponse = await fetch(process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/checkSession`, {
        method: 'GET',
        credentials: 'include',
      });
      const sessionData = await sessionDataResponse.json();
      setOrganizationId(sessionData.data.organizationId)
      return sessionData.data.organizationId;
    } catch (error) {
      console.error('Error fetching organizationId from session:', error);
      return null;
    }
  };
  useEffect(() => {
        fetchOrganizationIdFromSession();
  }, [organizationId]);
  // Declare organizationId and configuarationDetails
  var configurationTerms = location.state ? location.state.configurationTerms : ''
  const initialValues = configurationTerms
  const handleAddterms = async () => {
    try {
       await fetchOrganizationIdFromSession();
      setLoading(true);
      const values = await form.validateFields();
      const apiUrl = configurationTerms ? process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/configuration/terms/${organizationId}` : process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/terms`;
      const method = configurationTerms ? 'PATCH' : 'POST';

      const response = await fetch(apiUrl, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ values }),
      });

      if (response.ok) {
          const res_data =await response.json();
        //   console.log(res_data);
        message.success(res_data.message)
        navigate("/manage-setting")
      } else {
        console.error('Failed to add/update configuration. Server returned:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error adding/updating configuration:', error);
    } finally {
      setLoading(false);
    }
  };
  const renderLabel = (label, required) => (
    <span style={{ fontWeight: 'bold' }}>
      {label} {required && <span style={{ color: 'red' }}>*</span>}
    </span>
  );
  return (
    <div className='theme-container'>
      <div className='button-container'>
        <div className='title-div'>
          <h5 className='mb-0'>{initialValues  ? 'Update' : 'Update'} Terms</h5>
        </div>
      </div>
      <hr />
      <Form className='text-center pt-3' form={form} layout="vertical" initialValues={initialValues}>
        <Row gutter={20}>
        
          <Col span={8}>
            <Form.Item
              label={renderLabel('Terms (In Days)', true)}
              name="terms"
              rules={[  { required: true, message: 'Please enter the terms' },
                        { pattern: /^[0-9]*$/, message: 'Please enter a valid numeric number' },
                    ]}
            >
              <Input placeholder="Enter Terms" className='form-control' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Button style={{ top: '31px'}} className='btn-primary col-lg-3' type="primary" loading={loading} onClick={handleAddterms}>
                {initialValues  ? 'Update' : 'Update'}
            </Button>
          </Col>
        </Row>
       
      </Form>
    </div>
  );
};

export default AddTerms;
