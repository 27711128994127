import React, { useState } from 'react';
import { Link,useLocation,useNavigate } from 'react-router-dom';
import { FaUsers, FaCog, FaFileAlt } from 'react-icons/fa';
import { FileText, Users, Settings } from 'react-feather';
import './Sidebar.css';

const Sidebar = ({ isSidebarOpen }) => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  // const location = useLocation();
  // var clientId = location.state ? location.state.clientId : ''
  const navigate = useNavigate();
  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
    if(menuItem == 'invoice'){
      navigate('/manage-invoice');
    }else if (menuItem == 'clients') {
      navigate('/manage-clients');
    }else if(menuItem == 'setting'){
      navigate('/manage-setting');
    }
  };

  return (
    <aside className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
      <ul className="menu">
        <li>
          <div
            className={`menu-item ${activeMenuItem === 'clients' ? 'active' : ''}`}
            onClick={() => handleMenuItemClick('clients')}
          >
            <Link to="/manage-clients" className="icon-text-container">
              <Users className="icon-svg" />
              <span>Clients</span>
            </Link>
          </div>
        </li>
        <li>
          <div
            className={`menu-item ${activeMenuItem === 'invoice' ? 'active' : ''}`}
            onClick={() => handleMenuItemClick('invoice')}
          >
            <Link to="/manage-invoice" className="icon-text-container">
              <FileText className="icon-svg" />
              <span>Invoices</span>
            </Link>
          </div>
        </li>
        <li>
          <div
            className={`menu-item ${activeMenuItem === 'setting' ? 'active' : ''}`}
            onClick={() => handleMenuItemClick('setting')}
          >
            <Link to="/manage-setting" className="icon-text-container">
              <Settings className="icon-svg" />
              <span>Configuration</span>
            </Link>
          </div>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
