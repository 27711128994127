// AddContact.js
import React, { useState } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import './manageClient.css';
import { useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


const AddContact = ({ onSubmit }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  var clientDetails = location.state ? location.state.clientDetails : ''
  const initialValues = clientDetails
  const handleAddUpdateContact = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      // form.resetFields();
      const sessionDataResponse = await fetch(process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/checkSession`, {
        method: 'GET',
        credentials: 'include',
      });

      const sessionData = await sessionDataResponse.json();
      console.log(sessionData);
      const user_id = sessionData.data.userId;
      // values.user_id = user_id;
      values.created_by = user_id;
      const organizationId = sessionData.data.organizationId;
      values.organization_id = organizationId;
      const apiUrl = clientDetails ? process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/client/${clientDetails.client_id}` : process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/client`;
      const method = clientDetails ? 'PATCH' : 'POST';

      const response = await fetch(apiUrl, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ values }),
      });

      if (response.ok) {
        navigate("/manage-clients")
      } else {
        console.error('Failed to add/update client. Server returned:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error adding/updating client:', error);
    } finally {
      setLoading(false);
    }
  };
  const renderLabel = (label, required) => (
    <span style={{ fontWeight: 'bold' }}>
      {label} {required && <span style={{ color: 'red' }}>*</span>}
    </span>
  );
  return (
    <div className='theme-container'>
      <div className='button-container'>
        <div className='title-div'>
          <h5 className='mb-0'>{clientDetails ? 'Update' : 'Add'} Client</h5>
        </div>
        <div className='button-div' style={{ marginLeft: 'auto', display: clientDetails ? 'block' : 'none' }}>
          <Button className='btn-primary' type='primary' onClick={() => navigate(`/manage-invoice/client/${clientDetails.client_id}`)}>Transactions</Button>
        </div>
      </div>
          <hr/>
      <Form className='text-center pt-3' form={form} layout="vertical" initialValues={initialValues}>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              label={renderLabel('First Name', true)}
              name="client_first_name"
              rules={[{ required: true, message: 'Please enter the first name' }]}
            >
              <Input placeholder= "Enter First Name" className='form-control'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={renderLabel("Last Name", false)}
              name="client_last_name"
            >
              <Input placeholder= "Enter Last Name" className='form-control'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={renderLabel("Company Name", true)}
              name="client_company_name"
              rules={[{ required: true, message: 'Please enter the company name' }]}
            >
              <Input placeholder= "Enter Company Name" className='form-control'/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              label={renderLabel("Contact Number", true)}
              name="contact_number"
              rules={[
                { required: true, message: 'Please enter the phone number' },
                { pattern: /^[0-9]*$/, message: 'Please enter a valid numeric phone number' },
              ]}
            >
              <Input placeholder= "Enter Phone Number" className='form-control'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={renderLabel("Email", true)}
              name="email"
              rules={[
                { required: true, message: 'Please enter the email address' },
                { type: 'email', message: 'Please enter a valid email address' },
              ]}
            >
              <Input placeholder= "Enter Email" className='form-control'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={renderLabel("Address 1", false)}
              name="address1"
            >
              <Input placeholder= "Enter Address" className='form-control'/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              label={renderLabel("Address 2", false)}
              name="address2"
            >
              <Input placeholder= "Enter Address" className='form-control'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={renderLabel("City", false)}
              name="city"
            >
              <Input placeholder= "Enter City" className='form-control'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={renderLabel("state", false)}
              name="state"
            >
              <Input placeholder= "Enter state" className='form-control'/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              label={renderLabel("Zip Code", false)}
              name="zipcode"
            >
              <Input  placeholder= "Enter Zip Code" className='form-control'/>
            </Form.Item>
          </Col>
        </Row>
        <Button className='btn-primary col-lg-3' type="primary" loading={loading} onClick={handleAddUpdateContact}>
          {clientDetails ? 'Update' : 'Save'}
        </Button>
      </Form>
    </div>
  );
};

export default AddContact;
