import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Input, Button, Pagination, Spin,message } from 'antd';
import {EditFilled, SearchOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import 'antd/dist/antd';
import './manageConfiguration.css';
const ManageConfiguration = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [organizationId, setorganizationId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [lateFees, setLateFees] = useState([]);
  const navigate = useNavigate();
  const [sendMailOnInvoiceGeneration, setSendMailOnInvoiceGeneration] = useState(false);
  const [keepInvoiceInCC, setKeepInvoiceInCC] = useState(false);
  const [sendEmailToAdminAfterPayment, setSendEmailToAdminAfterPayment] = useState(false);
  const [sendEmailToCustomerAfterPayment, setSendEmailToCustomerAfterPayment] = useState(false);
  const [ccEmails, setCcEmails] = useState(['']);
  const [emailsConfig, setEmailsConfig] = useState(false);

  const handleAddCCEmail = () => {
    setCcEmails([...ccEmails, '']);
  };

  const handleRemoveCCEmail = (index) => {
    if (ccEmails.length > 1) {
      const newCcEmails = ccEmails.filter((_, i) => i !== index);
      setCcEmails(newCcEmails);
    }
  };

  const handleCCEmailChange = (index, value) => {
    const newCcEmails = [...ccEmails];
    newCcEmails[index] = value;
    setCcEmails(newCcEmails);
  };

  const saveEmailConfiguration = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/configuration/email-config/${organizationId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sendMailOnInvoiceGeneration: sendMailOnInvoiceGeneration ? '1' : '0',
            keepInvoiceInCC: keepInvoiceInCC ? '1' : '0',
            sendEmailToAdminAfterPayment: sendEmailToAdminAfterPayment ? '1' : '0',
            sendEmailToCustomerAfterPayment: sendEmailToCustomerAfterPayment ? '1' : '0',
            ccEmails,
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to save email configuration');
      }
      message.success('Email configuration saved')
      const result = await response.json();
      console.log('Email configuration saved:', result);
    } catch (error) {
      console.error('Error saving email configuration:', error);
    }
  };

  const fetchEmailConfiguration = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MASTER_URL}:${process.env.REACT_APP_PORT}/configuration/email-config/${organizationId}`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch email configuration');
      }

      const data = await response.json();
      setSendMailOnInvoiceGeneration(data[0] && data[0].send_mail_on_invoice_generation === '1');
      setKeepInvoiceInCC(data[0] && data[0].keep_invoice_in_cc === '1');
      setSendEmailToAdminAfterPayment(data[0] && data[0].is_admin_mail_after_payment === '1');
      setSendEmailToCustomerAfterPayment(data[0] && data[0].is_customer_mail_after_payment === '1');
      setCcEmails(data[0] && JSON.parse(data[0].cc_emails) || ['']);
    } catch (error) {
      console.error('Error fetching email configuration:', error);
    }
  };

  useEffect(() => {
    fetchEmailConfiguration();
  }, [organizationId]);
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        setLoading(true);
        const sessionDataResponse = await fetch(process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/checkSession`, {
          method: 'GET',
          credentials: 'include',
        });

        const sessionData = await sessionDataResponse.json();
        const user_id = sessionData.data.userId;
        const organization_Id = sessionData.data.organizationId;
        setorganizationId(organization_Id);
        const response = await fetch(
          process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/configuration?organization_id=${organization_Id}&page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const responseData = await response.json();
        setData(responseData.data);
        setTotalCount(responseData.total);
      } catch (error) {
        console.error('Error fetching table data:', error);
      }finally {
        setLoading(false);
      }
    };
    fetchTableData();
    handleTerms()
    handleLateFees()
  }, [page, pageSize, searchTerm,organizationId]);

  const handleTerms = async () => {
    const response = await fetch(process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/configuration/terms/${organizationId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const configurationTerms = await response.json();
    setTerms(configurationTerms.terms)
  };
  const handleLateFees = async () => {
    const response = await fetch(process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/configuration/late-fees/${organizationId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const LateFeesConfiguration = await response.json();
    setLateFees(LateFeesConfiguration)
  };
  const handleAddNewConfiguration = () => {
    navigate('/add-setting');
  };
  const handleAddTerms = async () => {
    
    const response = await fetch(process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/configuration/terms/${organizationId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const configurationTerms = await response.json();
    setTerms(configurationTerms.terms)
    navigate('/add-terms', { state: { configurationTerms } });
  };
  const handleAddLateFess = async () => {
    const response = await fetch(process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/configuration/late-fees/${organizationId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const LateFeesConfiguration = await response.json();
    navigate('/add-late-fees', { state: { LateFeesConfiguration } });
  };
  const handleEdit = async (configurationId) => {
    try {
      const response = await fetch(process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/configuration/${configurationId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const configurationDetails = await response.json();
      navigate('/add-setting', { state: { configurationDetails } });
    } catch (error) {
      console.error('Error fetching contact details:', error);
    }
  };

  const columns = [
    { title: 'ID', dataIndex: 'index', key: 'index', render: (text, record, index) => (page - 1) * pageSize + index + 1 },
    { title: 'Title', dataIndex: 'name', key: 'name' },
    { title: 'Percentage (%)', dataIndex: 'percentage', key: 'percentage' },
    {
      title: 'Type',
      dataIndex: 'is_tax',
      key: 'is_tax',
      render: (isTax) => {
        return isTax == 1 ? 'Tax' : 'Additional Fees';
      }
    },
    {
      title: 'Action',
      dataIndex: 'organization_tax_configuration_id',
      key: 'action',
      render: (taxConfigurationId) => (
        <Button type="link" onClick={() => handleEdit(taxConfigurationId)} icon={<EditFilled />} >Edit </Button>
      ),
    },
  ];

  return (
    <div className='theme-container'>
      <div>
      <div className='button-container'>
        <div className='title-div'>
        <h4 className='mb-0'>Configurations</h4>
        </div>
        <div className='button-div' style={{ marginLeft: 'auto' }}>
          <Button className='btn-primary' style={{ marginRight: '20px' }} type='primary' onClick={handleAddNewConfiguration}>Add Tax/Convenience</Button>
        
          <Button className='btn-primary' style={{ marginRight: '20px' }} type='primary' onClick={handleAddTerms}>Update Terms</Button>
       
          <Button className='btn-primary' type='primary' onClick={handleAddLateFess}>Update Late Fees</Button>
        </div>
      </div>
      <hr/>
        <div className='title-div'>
        <h6 className='mb-0'>Tax/Convenience Configurations</h6>
        </div>
        <hr/>
      </div>
      <div className='search-container'>
        <Input
          placeholder='Search'
          prefix={<SearchOutlined />}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className='table-container'>
        <Pagination
          showSizeChanger
          pageSizeOptions={['10', '20', '30', '40']}
          onShowSizeChange={(current, newSize) => setPageSize(newSize)}
        />
        <Spin spinning={loading}>
          <Table dataSource={data} columns={columns} pagination={false} rowKey={(record) => record.organization_tax_configuration_id} responsive/>
        </Spin>
      </div>
      <div className='pagination-container'>
        <Pagination
          pageSize={pageSize}
          total={totalCount}
          onChange={(newPage) => setPage(newPage)}
          showQuickJumper
          showTotal={(total) => `Total ${total} items`}
        />
      </div>
      
      <div className='title-div'>
      <hr/>
        <h6 className='mb-15'>Late Fee Configuration</h6>
        <hr/>
        <div className="late-fee-info">
          <table>
            <tbody>
              {lateFees.penalty_value_type && (
                <>
                  <tr>
                    <th>S/N</th>
                    <th>Title</th>
                    <th>Penalty Type</th>
                    <th>Penalty Value Type</th>
                    <th>Penalty Value</th>
                    <th>Action</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>{lateFees.penalty_title}</td>
                    <td>
                      {lateFees.penalty_type == '1' && 'Fixed'}
                      {lateFees.penalty_type == '2' && 'Daily'}
                      {lateFees.penalty_type == '3' && 'Weekly'}
                      {lateFees.penalty_type == '4' && 'Monthly'}
                    </td>
                    <td>
                      {lateFees.penalty_value_type == '1' && 'Fixed Value'}
                      {lateFees.penalty_value_type == '2' && 'Percentage'}
                    </td>
                    <td>{lateFees.penalty_value}</td>
                    <td><Button type='link' onClick={handleAddLateFess} icon={<EditFilled />} >Edit </Button></td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="title-div">
      <hr />
      <h6 className="mb-15">Email Configuration</h6>
      <hr />
      <div className="send-mail-info">
        <div className="checkbox-group">
          <label htmlFor="sendMailOnInvoiceGeneration">Send email on invoice generation</label>
          <input
            id="sendMailOnInvoiceGeneration"
            type="checkbox"
            checked={sendMailOnInvoiceGeneration}
            onChange={(e) => setSendMailOnInvoiceGeneration(e.target.checked)}
          />
        </div>

        <div className="checkbox-group">
          <label htmlFor="sendEmailToAdminAfterPayment">Send email to admin after payment</label>
          <input
            id="sendEmailToAdminAfterPayment"
            type="checkbox"
            checked={sendEmailToAdminAfterPayment}
            onChange={(e) => setSendEmailToAdminAfterPayment(e.target.checked)}
          />
        </div>

        <div className="checkbox-group">
          <label htmlFor="sendEmailToCustomerAfterPayment">Send email to customer after payment</label>
          <input
            id="sendEmailToCustomerAfterPayment"
            type="checkbox"
            checked={sendEmailToCustomerAfterPayment}
            onChange={(e) => setSendEmailToCustomerAfterPayment(e.target.checked)}
          />
        </div>

        <div className="checkbox-group">
          <label htmlFor="keepInvoiceInCC">Keep the invoice in CC</label>
          <button className="add-cc-email"  style={{ display: keepInvoiceInCC ? 'inline-block' : 'none' }} onClick={handleAddCCEmail}>
                <PlusOutlined />
          </button>
          <input
            id="keepInvoiceInCC"
            type="checkbox"
            checked={keepInvoiceInCC}
            onChange={(e) => setKeepInvoiceInCC(e.target.checked)}
          />
          {keepInvoiceInCC && (
            <div className="cc-email-inputs">
              {ccEmails.map((email, index) => (
                <div key={index} className="cc-email-input">
                  <Input
                    type="email"
                    placeholder="Enter CC email"
                    value={email}
                    onChange={(e) => handleCCEmailChange(index, e.target.value)}
                  />
                  {ccEmails.length > 1 && (
                    <MinusCircleOutlined onClick={() => handleRemoveCCEmail(index)} />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Add Save Button */}
        <button className="save-button float-right btn btn-sm btn-primary" onClick={saveEmailConfiguration}>
          Save Email Configuration
        </button>
      </div>
    </div>
    </div>
  );
};

export default ManageConfiguration;
