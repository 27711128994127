// src/index.js

import React from 'react';
import './assets/styles/index.css';
import App from './components/App';
import { BrowserRouter } from 'react-router-dom';
// import './App.css'
import { createRoot } from 'react-dom/client';
const root = createRoot(document.getElementById('root'));
console.log = function() {};
root.render(
    <BrowserRouter  basename="/">
      <App />
    </BrowserRouter>
  );
  
// src/index.js

// import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter } from 'react-router-dom';
// import App from './components/App';
// import './assets/styles/index.css';

// ReactDOM.render(
//   <BrowserRouter basename="/">
//     <App />
//   </BrowserRouter>,
//   document.getElementById('root')
// );



// import ReactDOM from 'react-dom';
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);

// ReactDOM.render(
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>,
//     document.getElementById('root')
//     );

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
