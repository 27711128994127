import React, { useState, useEffect } from 'react';
import Header from './common/Header';
import Login from '../login/Login';
import '../App.css';
import { Routes, Route } from 'react-router-dom'; 
import ManageClients from '../../src/components/client/ManageClient';
import AddClient from '../../src/components/client/AddClient';
import Dashboard from '../../src/components/Dashboard';
import AddInvoice from '../../src/components/invoices/addInvoice';
import ManageInvoice from '../../src/components/invoices/ManageInvoice';
import AddConfiguaration from '../../src/components/configuration/AddConfiguration';
import AddTerms from '../../src/components/configuration/AddTerms';
import AddLateFees from '../../src/components/configuration/AddLateFees';
import ManageConfiguration from '../../src/components/configuration/ManageConfiguration';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isSessionCheckComplete, setSessionCheckComplete] = useState(false);

  useEffect(() => {
    checkSession();
  }, [isLoggedIn]);

  const checkSession = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/checkSession`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      if (data.success) {
        setLoggedIn(true);
      }
    } catch (error) {
      console.error('Error checking session:', error);
    } finally {
      setSessionCheckComplete(true);
    }
  };
  const handleLogout = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/login/logout`, {
        method: 'POST',
        credentials: 'include',
      });

      if (response.ok) {
        setLoggedIn(false);
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };
  if (!isSessionCheckComplete) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return <Login onLogin={() => { setLoggedIn(true); setSidebarOpen(true); }} />;

  }

  return (
    <div>
      <Header isSidebarOpen={isSidebarOpen} setSidebarOpen={setSidebarOpen} onLogout={handleLogout} />
      <div className="container-fluid">
      <main className={`app-container ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <Routes>
          <Route path="/manage-clients" element={<ManageClients />} />
          <Route path="/add-client" element={<AddClient />} />
          <Route path="/add-invoice" element={<AddInvoice />} />
          <Route path="/manage-invoice" element={<ManageInvoice />} />
          <Route path="/manage-invoice/client/:id" element={<ManageInvoice />} />
          <Route path="/add-setting" element={<AddConfiguaration />} />
          <Route path="/add-late-fees" element={<AddLateFees />} />
          <Route path="/add-terms" element={<AddTerms />} />
          <Route path="/manage-setting" element={<ManageConfiguration />} />
          <Route path="/" element={<ManageClients />} />
        </Routes>
      </main>
      </div>
    </div>
  );
}

export default App;
