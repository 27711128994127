import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Input, Button, Pagination,Spin, DatePicker } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
import moment  from 'moment';
import 'antd/dist/antd';
import './manageClient.css';
const { RangePicker } = DatePicker;
const ManageClients = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([
    moment().subtract(1, 'month'),
    moment(),
  ]);
  const [sortColumn, setSortColumn] = useState('client_id');
  const [sortOrder, setSortOrder] = useState('desc');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        setLoading(true);
        const sessionDataResponse = await fetch(process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/checkSession`, {
          method: 'GET',
          credentials: 'include',
        });

        const sessionData = await sessionDataResponse.json();
        const user_id = sessionData.data.userId;
        const organizationId = sessionData.data.organizationId;
        let url = `${process.env.REACT_APP_MASTER_URL}:${process.env.REACT_APP_PORT}/client?organization_id=${organizationId}&page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`;

        
        // if (dateRange.length === 2) {
        //   const [startDate, endDate] = dateRange;
        //   url += `&startDate=${startDate.toISOString().substring(0,10)}&endDate=${endDate.toISOString().substring(0,10)}`;
        // }

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const responseData = await response.json();
        setData(responseData.data);
        setTotalCount(responseData.total);
      } catch (error) {
        console.error('Error fetching table data:', error);
      }finally {
        setLoading(false);
      }
    };

    fetchTableData();
  }, [page, pageSize, searchTerm,dateRange,sortColumn,sortOrder]);

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSortColumn(sorter.field);
      setSortOrder(sorter.order === 'ascend' ? 'asc' : 'desc');
    }
  };
  
  const handleAddNewClient = () => {
    navigate('/add-client');
  };

  const handleEdit = async (clientId) => {
    try {
      const response = await fetch(process.env.REACT_APP_MASTER_URL +`:${process.env.REACT_APP_PORT}/client/${clientId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const clientDetails = await response.json();
      navigate('/add-client', { state: { clientDetails } });
    } catch (error) {
      console.error('Error fetching contact details:', error);
    }
  };

  const columns = [
    { title: 'ID', dataIndex: 'index', key: 'index', render: (text, record, index) => (page - 1) * pageSize + index + 1 },
    {
      title: 'First Name',
      dataIndex: 'client_first_name',
      key: 'client_first_name',
      sorter:true,
      render: (text, record) => (
        <Button type="link" onClick={() => handleEdit(record.client_id)}>
          {text}
        </Button>
      )
    },
        // { title: 'First Name', dataIndex: 'client_first_name', key: 'client_first_name' },
    { title: 'Last Name', dataIndex: 'client_last_name', key: 'client_last_name',sorter:true },
    { title: 'Company Name', dataIndex: 'client_company_name', key: 'client_company_name',sorter:true },
    { title: 'Email', dataIndex: 'email', key: 'email',sorter:true },
    { title: 'Contact Number', dataIndex: 'contact_number', key: 'contact_number',sorter:true },
    // { title: 'Address 1', dataIndex: 'address1', key: 'address1' },
    // { title: 'Address 2', dataIndex: 'address2', key: 'address2' },
    { title: 'City', dataIndex: 'city', key: 'city' },
    { title: 'State', dataIndex: 'state', key: 'state' },
    // { title: 'Due Amount', dataIndex: 'total_invoice_amount', key: 'total_invoice_amount' },
    // { title: 'Zip Code', dataIndex: 'zipcode', key: 'zipcode' },
    {
      title: 'Due Amount',
      dataIndex: 'total_invoice_amount',
      key: 'total_invoice_amount',
      sorter:true,
      render: (DueAmount) => (
        DueAmount ? `$${DueAmount}` : ''
      ),
    },
    {
      title: 'Action',
      dataIndex: 'client_id',
      key: 'action',
      render: (clientId) => (
        <Button type="link" onClick={() => handleEdit(clientId)} icon={<EditFilled />} >Edit
        </Button>
      ),
    },
  ];

  return (
    <div className='theme-container'>
      <div>
      <div className='button-container'>
        <div className='title-div'>
        <h5 className='mb-0'>Manage Clients</h5>
        </div>
        <div className='button-div' style={{ marginLeft: 'auto' }}>
          <Button className='btn-primary' type='primary' onClick={handleAddNewClient}>Add Client</Button>
        </div>
      </div>
      <hr/>
      </div>
      <div className='search-container'>
        {/* <RangePicker
          onChange={(dates) => setDateRange(dates)}
          format="MM/DD/YYYY"
          value={dateRange}
        /> */}
        <Input
          placeholder='Search'
          prefix={<SearchOutlined />}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className='date-filter-container'>
        
      </div>

      <div className='table-container'>
        <Pagination
          showSizeChanger
          pageSizeOptions={['10', '20', '30', '40']}
          onShowSizeChange={(current, newSize) => setPageSize(newSize)}
        />
        <Spin spinning={loading}>
          <Table dataSource={data} columns={columns} pagination={false} onChange={handleTableChange} rowKey={(record) => record.client_id} responsive scroll={{ x: true }}/>
        </Spin>
      </div>
      <div className='pagination-container'>
        <Pagination
          pageSize={pageSize}
          total={totalCount}
          onChange={(newPage) => setPage(newPage)}
          showQuickJumper
          showTotal={(total) => `Total ${total} items`}
        />
      </div>
    </div>
  );
};

export default ManageClients;
