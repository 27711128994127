// Header.js
import React from 'react';
import brandLogo from '../../assets/images/surfside-logo.png';
import userLogo from '../../assets/images/user-logo.png';
import Sidebar from './Sidebar';
import './Header.css';
import { UserOutlined, LogoutOutlined,PoweroffOutlined  } from '@ant-design/icons';
const Header = ({ isSidebarOpen, setSidebarOpen, onLogout }) => {
  return (
    <div>
      <header>
        <div className="brand-logo">
          <img src={brandLogo} alt="Brand Logo" />
        </div>
        <div className="toggle-button" onClick={() => setSidebarOpen((prev) => !prev)}>
          &#9776;
        </div>
        <div className="user-info">
          <img src={userLogo} alt="User Logo" />
          <button onClick={onLogout}>
            {/* <PoweroffOutlined /> */}
            Logout
          </button>
        </div>
      </header>
      <Sidebar isSidebarOpen={isSidebarOpen}/>
    </div>
  );
};

export default Header;
