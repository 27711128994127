// AddContact.js
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Select,Switch} from 'antd';
import './manageConfiguration.css';
import { useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
const { Option,message } = Select;

const AddConfiguaration = ({ onSubmit }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const fetchOrganizationIdFromSession = async () => {
    try {
      const sessionDataResponse = await fetch(process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/checkSession`, {
        method: 'GET',
        credentials: 'include',
      });
      const sessionData = await sessionDataResponse.json();
      return sessionData.data.organizationId;
    } catch (error) {
      console.error('Error fetching organizationId from session:', error);
      return null;
    }
  };

  // Declare organizationId and configuarationDetails
  const [organizationId, setOrganizationId] = useState(null);
  var configurationDetails = location.state ? location.state.configurationDetails : ''
  const initialValues = configurationDetails
  console.log(initialValues);

  const handleAddConfiguaration = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      // form.resetFields();
      const sessionDataResponse = await fetch(process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/checkSession`, {
        method: 'GET',
        credentials: 'include',
      });

      const sessionData = await sessionDataResponse.json();
      console.log(sessionData);
      const user_id = sessionData.data.userId;
      // values.user_id = user_id;
      values.created_by = user_id;
      const organizationId = sessionData.data.organizationId;
      values.organization_id = organizationId;
      values.is_apply_on = values.is_apply_on ? '1' : '0';
      const apiUrl = configurationDetails ? process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/configuration/${configurationDetails.organization_tax_configuration_id}` : process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/configuration`;
      const method = configurationDetails ? 'PATCH' : 'POST';

      const response = await fetch(apiUrl, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ values }),
      });

      if (response.ok) {
        navigate("/manage-setting")
      } else {
        console.error('Failed to add/update configuration. Server returned:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error adding/updating configuration:', error);
    } finally {
      setLoading(false);
    }
  };
  const renderLabel = (label, required) => (
    <span style={{ fontWeight: 'bold' }}>
      {label} {required && <span style={{ color: 'red' }}>*</span>}
    </span>
  );
  return (
    <div className='theme-container'>
      <div className='button-container'>
        <div className='title-div'>
          <h5 className='mb-0'>{initialValues  ? 'Update' : 'Add'} Configuration</h5>
        </div>
      </div>
      <hr />
      <Form className='text-center pt-3' form={form} layout="vertical" initialValues={initialValues}>
        <Row gutter={20}>
        <Col span={7}>
            <Form.Item
              label={renderLabel("Type", true)}
              name="is_tax"
              rules={[{ required: true, message: 'Please Select Type' }]}
              initialValue="1"
            >
            <Select className='text-left' placeholder="Select Type" >
              <Option value="1">Tax</Option>
              <Option value="0">Additional fees</Option>
            </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label={renderLabel('Title', true)}
              name="name"
              rules={[{ required: true, message: 'Please enter the Title' }]}
            >
              <Input placeholder="Enter Title" className='form-control' />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label={renderLabel("Percentage (%)", true)}
              name="percentage"
              rules={[
                { required: true, message: 'Enter Percentage' },
                { pattern: /^[0-9]+(\.[0-9]+)?$/, message: 'Enter a valid number' },
            ]}
            >
              <Input placeholder="Enter percentage" className='form-control' />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              label={renderLabel("Auto Apply", false)}
              name="is_apply_on"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Button className='btn-primary col-lg-3' type="primary" loading={loading} onClick={handleAddConfiguaration}>
          {initialValues  ? 'Update' : 'Save'}
        </Button>
      </Form>
    </div>
  );
};

export default AddConfiguaration;
