// AddContact.js
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Select} from 'antd';
import './manageConfiguration.css';
import { useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
const { Option } = Select;

const UpdateLateFeesConfiguration = ({ onSubmit }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [organizationId, setOrganizationId] = useState('');

  const fetchOrganizationIdFromSession = async () => {
    try {
      const sessionDataResponse = await fetch(process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/checkSession`, {
        method: 'GET',
        credentials: 'include',
      });
      const sessionData = await sessionDataResponse.json();
      setOrganizationId(sessionData.data.organizationId)
      return sessionData.data.organizationId;
    } catch (error) {
      console.error('Error fetching organizationId from session:', error);
      return null;
    }
  };
  useEffect(() => {
      fetchOrganizationIdFromSession();
  }, [organizationId]);
  var LateFeesConfiguration = location.state ? location.state.LateFeesConfiguration : ''
  const initialValues = LateFeesConfiguration

  const handleUpdateLateFeesConfiguration = async () => {
    try {
      await fetchOrganizationIdFromSession();
      setLoading(true);
      const values = await form.validateFields();
      const apiUrl = LateFeesConfiguration ? process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/configuration/late-fees/${organizationId}` : process.env.REACT_APP_MASTER_URL + `:${process.env.REACT_APP_PORT}/late-fees`;
      const method = LateFeesConfiguration ? 'PATCH' : 'POST';
      const response = await fetch(apiUrl, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ values }),
      });

      if (response.ok) {
        navigate("/manage-setting")
      } else {
        console.error('Failed to add/update configuration. Server returned:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error adding/updating configuration:', error);
    } finally {
      setLoading(false);
    }
  };
  const renderLabel = (label, required) => (
    <span style={{ fontWeight: 'bold' }}>
      {label} {required && <span style={{ color: 'red' }}>*</span>}
    </span>
  );
  return (
    <div className='theme-container'>
      <div className='button-container'>
        <div className='title-div'>
          <h5 className='mb-0'>Update Late Fees Configuration</h5>
        </div>
      </div>
      <hr />
      <Form className='text-center pt-3' form={form} layout="vertical" initialValues={initialValues}>
        <Row gutter={20}>
        <Col span={6}>
            <Form.Item
              label={renderLabel("Title", true)}
              name="penalty_title"
              rules={[
                { required: true, message: 'Enter Title' },
            ]}
            >
              <Input placeholder="Title" className='form-control' />
            </Form.Item>
          </Col>
        <Col span={6}>
            <Form.Item
              label={renderLabel("Penalty Type", true)}
              name="penalty_type"
              rules={[{ required: true, message: 'Please Select Penalty Type' }]}
             
            >
            <Select className='text-left' placeholder="Select Penalty Type" >
              <Option value="1">Fixed</Option>
              <Option value="2">Daily</Option>
              <Option value="3">Weekly</Option>
              <Option value="4">Monthly</Option>
            </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={renderLabel('Penlty Value Type', true)}
              name="penalty_value_type"
              rules={[{ required: true, message: 'Please Select Penalty Value Type' }]}
              
            >
            <Select className='text-left' placeholder="Select Penalty Value Type" >
              <Option value="1">Fixed Value</Option>
              <Option value="2">Percentage</Option>
            </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={renderLabel("Penalty Value", true)}
              name="penalty_value"
              rules={[
                { required: true, message: 'Enter Penalty Value' },
                { pattern: /^[0-9]+(\.[0-9]+)?$/, message: 'Enter a valid number' },
            ]}
            >
              <Input placeholder="Penalty Value" className='form-control' />
            </Form.Item>
          </Col>
        </Row>
        <Button className='btn-primary col-lg-3' type="primary" loading={loading} onClick={handleUpdateLateFeesConfiguration}>
          {initialValues  ? 'Update' : 'Update'}
        </Button>
      </Form>
    </div>
  );
};

export default UpdateLateFeesConfiguration;
