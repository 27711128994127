import React, { useState,useEffect,useRef } from 'react';
import brandLogo from '../assets/images/surfside-logo.png';
import backgroundImage from '../assets/images/priority_pos_image.jpg';
import './Login.css';
import md5 from 'md5';
import { Input, Space, message } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';

const Login = ({ onLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const loginButtonRef = useRef(null);

    useEffect(() => {
        loginButtonRef.current.focus();
    }, []);

    const handleLogin = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_MASTER_URL}:${process.env.REACT_APP_PORT}/login`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        password: md5(password),
                    }),
                    credentials: 'include',
                }
            );
            const data = await response.json();
            if (data.success) {
                // Successful login
                message.success('Successfully logged in!');
                onLogin();
            } else {
                // Failed login
                message.error('Wrong  username or password');
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <div className="container-fluid">
            <div className="row justify-content-center align-items-center min-vh-100">
                <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-10">
                    <form className="login_form bg-white">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className='login_fields'>
                                    <div className="brand-logo mb-4 text-center">
                                        <img src={brandLogo} alt="Brand Logo" className="img-fluid" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="email" className="form-label">
                                            <Space>
                                                <MailOutlined />
                                                Email:
                                            </Space>
                                        </label>
                                        <Input
                                            type="email"
                                            className="form-control login-email"
                                            id="email"
                                            value={email}
                                            placeholder='Enter Your Email'
                                            onChange={(e) => setEmail(e.target.value)}
                                            onKeyPress={handleKeyPress}

                                            
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="password" className="form-label">
                                            <Space>
                                                <LockOutlined />
                                                Password:
                                            </Space>
                                        </label>
                                        <Input
                                            type="password"
                                            className="form-control login-password"
                                            id="password"
                                            value={password}
                                            placeholder='Enter Password'
                                            onChange={(e) => setPassword(e.target.value)}
                                            onKeyPress={handleKeyPress}

                                        />
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm login-btn"
                                        onClick={handleLogin}
                                        ref={loginButtonRef}

                                    >
                                        Login
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6 d-none d-md-block">
                                <div className="background-image">
                                    <img src={backgroundImage} alt="" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
